import Vue from 'vue';
import axios from 'axios';
export default {
    install(Vue, options) {
        Vue.prototype.$getSafe = function (fn) {
            try {
                return fn();
            } catch (e) {
                return ""
            }
        };
        Vue.prototype.$vuePublicPath = function (asset) {
            return process.env.BASE_URL + "public" + asset;
        };
        Vue.prototype.$resizeImage = function (path, width, height, quality, crop) {
            height = height || '';
            if (isBrowser && window.isMobile && width > window.innerWidth) {
                width = window.innerWidth;
                height = '';
            }
            //--------------------------------------------------
            let imgUrl = path;
            // if(/[/](.+)[_](.+)[x](.*)[.]/i.test(imgUrl)){   //pre-sized image url
            if(/[_](\d+)[x](\d+)[.]/i.test(imgUrl)){   //pre-sized image url
                let ext = imgUrl.split('/').pop().split('.').pop();
                let folderPath = imgUrl.split('/').reverse().slice(1).reverse().join('/');
                let imgName = imgUrl.split('/').pop().split('_').reverse().slice(1).reverse().join('_');
                return folderPath+"/"+imgName+"_"+width+"x"+height+"."+ext;
            }else{
                let ext = imgUrl.split('/').pop().split('.').pop();
                let folderPath = imgUrl.split('/').reverse().slice(1).reverse().join('/');
                // let imgName = imgUrl.split('/').pop().split('.')[0];
                // Set proper image name if we have multiple dot into name
                let imgName = imgUrl.split('/').pop().split('.').slice(0, -1).join('.');
                return folderPath+"/"+imgName+"_"+width+"x"+height+"."+ext;
            }
        };
        Vue.prototype.$getMetaTags = function (name, description, keywords, img, articleData) {

            img = img || (this.$siteURL + "public/images/logo.png");
            var keywordProperty = [{itemprop: "og:locale",content: "en_US"}];
            if(articleData){
                keywordProperty.push({property: "og:type",content: "article"});
                keywordProperty = keywordProperty.concat(articleData);
            }else{
                keywordProperty.push({property: "og:type",content: "website"});
            }

            keywordProperty.push(
                {
                    property: "og:url",
                    content: process.env.SITE_URL + this.$route.fullPath.substring(1)
                },
                {
                    property: "og:site_name",
                    content: 'SABC'
                },
                {
                    property: "og:title",
                    content: name || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    property: "og:description",
                    content: description || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    property: "og:image",
                    content: img
                },
                {
                    itemprop: "og:keywords",
                    content: keywords || ""
                }
            );
            keywordProperty.push(
                {
                    name: "twitter:card",
                    content: "summary_large_image"
                },
                {
                    name: "twitter:title",
                    content: name || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    name: "twitter:description",
                    content: description || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    name: "twitter:image",
                    content: img
                },
                {
                    itemprop: "twitter:keywords",
                    content: keywords || ""
                },
                {
                    name: "twitter:site",
                    content: "@sabc"
                }
            );

            var metaArray =  [
                {
                    name: "title",
                    content: name || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    name: "description",
                    content: description || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    name: "image",
                    content: img
                },
                {
                    name: "keywords",
                    content: keywords || ""
                },

                {
                    itemprop: "name",
                    content: name || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    itemprop: "description",
                    content: description || "Football, Cricket, and All Sports Latest News - SABC Sport"
                },
                {
                    itemprop: "image",
                    content: img
                },
                {
                    itemprop: "keywords",
                    content: keywords || ""
                },
            ]

            return metaArray.concat(keywordProperty);
        };

        Vue.prototype.$filterTeam = function (teams, is_home) {
            let team = teams.filter((item) => {
                if (item.isHome == is_home) {
                    return item;
                }
                else if(item.is_home == is_home) {
                    return item;
                }
            });
            return team[0];
        };
        Vue.prototype.$SPORTS = [
            {
                'id': 1,
                'name': 'Football',
                'slug': 'football',
            },

            {
                'id': 9,
                'name': 'Tennis',
                'slug': 'tennis',

            },
            {
                'id': 15,
                'name': 'Cricket',
                'slug': 'cricket',

            },
            {
                'id': 90,
                'name': 'Field Hockey',
                'slug': 'field-hockey'
            },
            {
                'id': 91,
                'name': 'Badminton',
                'slug': 'badminton'
            },
            {
                'id': 92,
                'name': 'Kabaddi',
                'slug': 'kabaddi'
            },
            {
                'id': 93,
                'name': 'Esports',
                'slug': 'esports'
            },
        ];

        Vue.prototype.$getMatchedSportsName = function (sport_id) {
            var valObj = this.$SPORTS.filter(function (elem) {
                if (elem.id == sport_id)
                    return elem.slug;
            });
            //  console.log(valObj[0].slug);
            return valObj.length > 0 ? valObj[0].slug : '';
        };
        Vue.prototype.$formatFootballScore = function (score) {
            return score.replace('-', ' - ');
        };
        Vue.prototype.$toFullPath = function (str) {
            var baseUrl = (process.env.SITE_URL) ? process.env.SITE_URL : process.env.BASE_URL;
            baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
            return baseUrl + str;
        };
        Vue.prototype.$replacePlaceHolders = function (str, replacements) {
            return str.replace(/%\w+%/g, function (all) {
                return replacements[all] || all;
            });
        };
        Vue.prototype.$slugify = function (str) {
            return str.toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '')
                    .replace(/\-\-+/g, '-');
        };
        Vue.prototype.$loadScript = function (url) {
            if (!this.$isScriptAlreadyIncluded(url)) {
                let script = document.createElement('script');
                script.setAttribute('src', url);
                document.head.appendChild(script);
            }
        };
        Vue.prototype.$isScriptAlreadyIncluded = function (src) {
            var scripts = document.getElementsByTagName("script");
            for (var i = 0; i < scripts.length; i++)
                if (scripts[i].getAttribute('src') == src)
                    return true;
            return false;
        }
        Vue.prototype.$findImage = function (logo, default_logo) {
          try {
            return require(`@/assets/images/${logo}`);
          } catch {
            return require(`@/assets/images/${default_logo}`)

          }
        };
        Vue.prototype.$teamDefaultLogo = function () {
            return "this.src='/public/images/officialLogo.png'";
        };

        Vue.prototype.$prepareAPICall = function (url, params = {}) {
            var apiKey = 'sabssportdvUKnJes3miSkwkEA0EBYNBwV9KflfBSLkIJvuuU3PI1Zq3Vcdc4uDAUVCjcFfTfgEgXZC49KYuF7Z53K76g5NVZYVcElr1zkVumsdztruzBp8u1AnFXYjW';
            return this.$axios.get(url, { headers: {'X-API-KEY': apiKey}, params})
        };

    }};
